import * as React from "react";
const SVGComponent = (props) => (
    <svg
        viewBox="0 0 1128 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M105.276 110.444H95.9595L82.4507 79.6444H22.6701L9.16119 110.444H0L48.135 1.55555H57.141L105.276 110.444ZM52.638 11.9778L26.2414 71.5556H78.8794L52.638 11.9778Z"
            fill="#6166AF"
        />
        <path
            d="M219.227 110.444H154.322V1.55555H163.017V102.356H219.227V110.444Z"
            fill="#6166AF"
        />
        <path
            d="M333.487 110.444H268.582V1.55555H277.277V102.356H333.487V110.444Z"
            fill="#6166AF"
        />
        <path d="M405.217 75.2889H372.61V67.3556H405.217V75.2889Z" fill="#6166AF" />
        <path
            d="M467.434 110.444H458.739V1.55555H467.434V110.444Z"
            fill="#6166AF"
        />
        <path
            d="M605.225 96.1333V1.55555H613.921V110.444H605.381L532.868 16.0222V110.444H524.172V1.55555H532.712L605.225 96.1333Z"
            fill="#6166AF"
        />
        <path
            d="M700.233 75.2889H667.625V67.3556H700.233V75.2889Z"
            fill="#6166AF"
        />
        <path
            d="M766.797 95.8222C756.031 85.037 750.648 71.763 750.648 56C750.648 40.237 756.031 26.963 766.797 16.1778C777.562 5.39259 790.864 0 806.702 0C822.54 0 835.842 5.39259 846.608 16.1778C857.373 26.963 862.756 40.237 862.756 56C862.756 71.763 857.373 85.037 846.608 95.8222C835.842 106.607 822.54 112 806.702 112C790.864 112 777.562 106.607 766.797 95.8222ZM773.163 22.0889C764.054 31.2148 759.499 42.5185 759.499 56C759.499 69.4815 764.054 80.837 773.163 90.0667C782.272 99.1926 793.452 103.756 806.702 103.756C819.952 103.756 831.132 99.1926 840.242 90.0667C849.351 80.837 853.906 69.4815 853.906 56C853.906 42.5185 849.351 31.2148 840.242 22.0889C831.132 12.8593 819.952 8.24445 806.702 8.24445C793.452 8.24445 782.272 12.8593 773.163 22.0889Z"
            fill="#6166AF"
        />
        <path
            d="M997.59 96.1333V1.55555H1006.29V110.444H997.746L925.232 16.0222V110.444H916.537V1.55555H925.077L997.59 96.1333Z"
            fill="#6166AF"
        />
        <path
            d="M1128 110.444H1063.1V1.55555H1128V9.64445H1071.79V51.9556H1126.45V60.0444H1071.79V102.356H1128V110.444Z"
            fill="#6166AF"
        />
    </svg>
);
export default SVGComponent;
