const SVGComponent = (props) => (
  <svg
    viewBox="0 0 1256 444"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M839.253 334.274C857.672 368.161 884.151 395.156 917.537 414.684C950.923 434.787 990.064 444.55 1033.81 443.976C1077.56 443.976 1116.12 434.212 1150.09 414.684C1183.47 395.156 1209.95 368.161 1228.37 334.274C1246.79 300.388 1256 263.054 1256 222.275C1256 181.496 1246.79 144.163 1228.37 110.276C1209.95 76.3892 1184.05 49.9689 1150.09 29.8665C1116.12 9.7641 1077.56 0 1033.81 0C990.064 0 950.923 9.7641 917.537 29.8665C884.151 49.9689 857.672 76.3892 839.253 110.276C820.833 144.163 811.623 181.496 811.623 222.275C811.623 263.054 820.833 300.388 839.253 334.274ZM911.781 147.609C924.444 125.209 941.713 107.979 963.01 95.3429C984.884 82.7071 1007.91 76.3891 1033.24 76.3891C1059.14 76.3891 1082.74 82.7071 1104.04 95.3429C1125.91 107.979 1142.6 125.209 1155.27 147.609C1167.93 169.435 1174.26 194.132 1174.26 221.701C1174.26 249.27 1167.93 273.967 1155.27 296.367C1142.6 318.767 1125.33 336.572 1104.04 349.208C1082.74 361.843 1059.14 368.161 1033.24 368.161C1007.91 368.161 984.308 361.843 963.01 349.208C941.713 336.572 924.444 318.767 911.781 296.367C899.117 273.967 892.785 249.27 892.785 221.701C892.785 194.706 899.117 170.009 911.781 147.609Z"
      fill="#4F408D"
    />
    <path
      d="M0 8.04236H315.438V44.2268C315.438 55.7139 311.985 64.9035 305.653 71.2214C298.745 77.5393 289.536 80.4111 276.872 80.4111H81.7378V187.241H265.935V221.128C265.935 233.764 262.482 242.953 256.15 249.846C249.242 256.738 240.033 259.61 227.369 259.61H82.3133V362.994H276.872C288.384 362.994 297.594 366.44 305.077 373.906C312.56 381.373 316.014 390.563 316.014 402.05V435.362H0V8.04236Z"
      fill="#4F408D"
    />
    <path
      d="M706.859 15.5094C714.342 10.9145 722.976 8.61706 733.913 8.61706H793.201L589.433 436.511H584.828L381.635 8.61706H440.348C451.285 8.61706 460.494 10.9145 467.402 15.5094C474.885 20.1042 480.641 27.5708 485.246 37.3348L587.13 257.313L689.015 37.3348C693.62 26.9964 699.376 20.1042 706.859 15.5094Z"
      fill="#4F408D"
    />
  </svg>
);
export default SVGComponent;
